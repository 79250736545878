export const locale = {
  lang: "dz",
  data: {
    FOLDER_NAV: {
      CONTEXT: "إدارة الملفات",
      DASHBOARD: {
        TITLE: "لوحة القيادة",
        BADGE: "",
      },
      PROFILE: {
        TITLE: "الملف الشخصي للمستخدم",
        BADGE: "",
      },
      PRODUCT: {
        TITLE: "المنتجات",
        BADGE: "",
      },
      STOCK: {
        TITLE: "المخزون",
        BADGE: "",
      },
      FOLDER: {
        TITLE: "الملف الحالي",
        BADGE: "",
      },
      CLIENTS: {
        TITLE: "الزبائن",
        BADGE: "",
      },
      SALES: {
        TITLE: "المبيعات",
        BADGE: "",
      },
      PRICE_LIST: {
        TITLE: "جدول الأسعار",
        BADGE: "",
      },
      ENTRY_VOUCHERS: {
        TITLE: "إيصالات الدخول",
        BADGE: "",
      },
      ALL_CLIENTS: {
        TITLE: "الزبائن",
        BADGE: "",
      },
      CLIENT_FAMILY: {
        TITLE: "عائلات الزبائن",
        BADGE: "",
      },
      QUOTE: {
        TITLE: "عروض الأسعار",
        BADGE: "",
      },
      BILLS: {
        TITLE: "الفواتير",
        BADGE: "",
      },
      PRODUCT_FAMILY: {
        TITLE: "عائلات المنتجات",
        BADGE: "",
      },
      ASSETS: {
        TITLE: 'الأصول',
        BADGE: ''
      },
      DELIVERY_NOTE: {
        TITLE: "إيصالات التسليم",
        BADGE: "",
      },
      RETURN_VOUCHER: {
        TITLE: "إيصالات الإرجاع",
      },
      RECEIPT_VOUCHER: {
        TITLE: "إيصالات الاستلام",
      },
      PURCHASES: {
        TITLE: "المشتريات",
        BADGE: "",
      },
      PROVIDERS: {
        TITLE: "الموردين",
        BADGE: "",
      },
      PROVIDER_INVOICES: {
        TITLE: "فواتير الموردين",
        BADGE: "",
      },
      CASHING: {
        TITLE: 'الإيداعات',
        BADGE: ''
      },
      TREASURERSHIP: {
        TITLE: 'الخزنة',
        BADGE: ''
      },
      BDC_PROVIDERS: {
        TITLE: 'فواتير الموردين BDC',
        BADGE: ''
      },
      RETURN_PROVIDER: {
        TITLE: 'إيصالات إرجاع الموردين',
        BADGE: ''
      },
      DISBURSMENTS: {
        TITLE: 'الصرفيات',
        BADGE: ''
      },
      DEPOSITS: {
        TITLE: 'الودائع',
        BADGE: ''
      },
      EXIT_VOUCHERS: {
        TITLE: "إيصالات الخروج",
        BADGE: ""
      },
      TRANSFER_VOUCHERS: {
        TITLE: "إيصالات النقل",
        BADGE: ""
      },
      FUNDINGBOX: {
        TITLE: 'صناديق النقد',
        BADGE: ''
      },
      SAV_QUATE: {
        TITLE: "Devis SAV",
        BADGE: "",
      },
      SAV_INTERVENTION: {
        TITLE: "Intervention SAV",
        BADGE: "",
      },
      SAV_INVOICE: {
        TITLE: "Facture SAV",
        BADGE: "",
      },
      INVENTORY: {
        TITLE: 'جرد المخزون',
        BADGE: ''
      },
      STATISTICS: {
        TITLE: "الإحصائيات",
        BADGE: "",
      },
      PRODUCTION: {
        TITLE: "إنتاج",
        BADGE: "",
      },
      PROCESS: {
        TITLE: "عملية",
        BADGE: "",
      },
      EXECUTION: {
        TITLE: "تنفيذ",
        BADGE: "",
      },
    },
    ADMIN_NAV: {
      CONTEXT: "الإدارة",
      ACCOUNT: {
        TITLE: "حساب الزبون الحالي",
        BADGE: "",
      },
      CLIENT_ADMINS: {
        TITLE: 'المسؤولون',
        BADGE: '',
      },
      ACCOUNT_CLIENTS: {
        TITLE: 'المستخدمون',
        BADGE: '',
      },
      TRACKS: {
        TITLE: 'السجل',
        BADGE: '',
      },
    },
    errors: {
      noBarcode: "المنتج المحدد لا يحتوي على رمز شريطي. الرجاء إدخاله أولاً على مستوى المنتج",
      createFolder: "الرجاء إنشاء ملف أولاً",
      standardErrorMessage403:
        "أنت غير مصرح لك بالوصول إلى هذه الوحدة، الرجاء التواصل مع المشرف",
      standardErrorMessagePrivileges403:
        "أنت غير مصرح لك بالوصول إلى هذه الوحدة، الرجاء التواصل مع المشرف",
      standardErrorMessage400: "طلب غير صالح: رسالة قياسية",
      standardErrorMessage404:
        "حدث خطأ في الاتصال بالخادم، الرجاء المحاولة لاحقاً",
      standardErrorMessage500:
        "حدث خطأ في الاتصال بالخادم، الرجاء المحاولة لاحقاً",
      standardErrorMessage504: "الرجاء المحاولة مرة أخرى",
      accountnameexists: "اسم الحساب موجود بالفعل",
      accountAlreadyActivated: "فشل في تنشيط الحساب",
      phoneexists: "رقم الهاتف موجود بالفعل",
      emailexists: "البريد الإلكتروني موجود بالفعل",
      errorParams: "البريد الإلكتروني و/أو كلمة المرور غير صحيحة",
      infoText: 'يرجى تقديم: ',
      commercialRegisterNumber: 'رقم السجل التجاري, ',
      taxationArticle: 'المادة الضريبية, ',
      bankAccountNumber: 'الرقم المصرفي, ',
      nif: 'الرقم الوطني للتعريف, ',
      nis: 'الرقم الإجتماعي للتعريف, ',
      infoFolderText: ' في الملف قبل الإنشاء',
      infoClientText: ' في الزبون قبل الإنشاء',
      E001: "فترة زمنية غير صالحة",
      E002: "تجاوز حصة المسؤولين في حساب الزبون",
      E003: "مدة أو اشتراك مفقود",
      E004: "تجاوز حصة المستخدمين في حساب الزبون",
      E005: "وحدة مكررة",
      E006: "تم إرسال دعوة بالفعل",
      E007: "وحدة مطلوبة",
      E008: "المستخدم موجود بالفعل",
      E009: "مستخدم BACK OFFICE",
      E010: "كلمة المرور غير صحيحة",
      E011: "اسم الاشتراك موجود بالفعل",
      E012: "يتم استخدام الاشتراك بواسطة حساب الزبون",
      E013: "يتم استخدام الاشتراك بواسطة حساب الزبون النشط",
      E014: "Recaptcha غير صالح",
      E015: "عدد ملفات حساب الزبون أكبر من الاشتراك",
      E016: "عدد الموظفين في حساب الزبون أكبر من الاشتراك",
      E017: "سعة تخزين حساب الزبون أكبر من الاشتراك",
      E018: "اشتراك غير موجود أو غير نشط",
      E019: "الاشتراك إلزامي",
      E020: "وحدة التخزين إلزامية",
      E021: "الحساب يجب أن يكون جديدًا أو غير نشط",
      E022: "لا يوجد مستخدم مرتبط بمفتاح التنشيط هذا",
      E023: "لا يوجد مستخدم غير نشط مرتبط بموظف",
      E025: "النطاق غير صالح",
      E026: "يرجى تقديم عنوان بريد إلكتروني موجود بالفعل",
      E100: "البلدية غير صالحة",
      E101: "النشاط غير صالح",
      E102: "الولاية غير صالحة",
      E103: "الاسم موجود بالفعل",
      E104: "النشاط مطلوب",
      E173: "الحقول مطلوبة",
      E361: "الحقول مطلوبة",
      E999:
        "من أجل أمانك، تم حظر حسابك مؤقتًا لمدة 5 دقائق، " +
        "نظرًا لوجود العديد من محاولات التسجيل الفاشلة",
      E603: 'رمز المنتج الشريطي موجود بالفعل',
      E600: 'المنتج موجود بالفعل',
      E604: "الاسم الأول مطلوب",
      E605: "الاسم الأخير مطلوب",
      E606: "اسم الشركة مطلوب",
      E607: "الشكل القانوني مطلوب",
      E608: "الاسم الأول والأخير موجودان بالفعل",
      E609: "اسم الشركة موجود بالفعل",
      E610: "الرمز موجود بالفعل",
      E611: "تنسيق الصورة غير مدعوم",
      E612: "تم تجاوز الحجم الأقصى للصورة",
      E616: "اسم جدول الأسعار موجود بالفعل",
      E615: "عائلة الزبون تملك جدول أسعار بالفعل",
      E622: "فشل في الحذف، الزبون لديه مستندات مبيعات",
      E627: 'اسم سند التسليم موجود بالفعل',
      E628: 'اسم الفاتورة موجود بالفعل',
      E629: 'المنتج مستخدم في جدول الأسعار',
      E614: 'هذه العائلة تحتوي على عملاء، لا يمكن حذفها',
      E619: 'الخصم لا يجب أن يتجاوز المبلغ الإجمالي',
      E626: 'المعلومات الضريبية مطلوبة',
      E631: "حقول مطلوبة مفقودة",
      E638: "هذه العائلة تحتوي على منتجات. يُرجى إزالة المنتجات قبل المحاولة مرة أخرى.",
      E636: 'الفاتورة مرتبطة بـ Avoir',
      E637: 'اسم عائلة المنتج موجود بالفعل',
      E601: "الحد الأقصى للكمية يجب أن يكون أكبر من الحد الأدنى للكمية",
      E613: "هذه العائلة تحتوي بالفعل على اسم مشابه",
      E617: "تاريخ الاستحقاق يجب أن يكون أكبر من تاريخ المستند",
      E618: "نوع الخصم إلزامي",
      E620: "المنتج يجب أن يحمل اسمًا",
      E621: "الكمية غير صالحة",
      E623: "المنتج ظاهر في مستند",
      E624: "حالة عرض سعر غير صالحة",
      E625: "حالة سند تسليم غير صالحة",
      E630: "الزبون غير نشط",
      E632: "رمز المورد موجود بالفعل",
      E633: "اسم العائد غير صالح",
      E634: "سند التسليم مرتبط بـ Retour",
      E635: "اسم Avoir غير صالح",
      E639: "اسم المورد موجود بالفعل",
      E640: "أحد المنتجات المرفوعة مرتبط بـ Retour",
      E641: "أحد المنتجات المرفوعة مرتبط بـ Avoir",
      E642: "حقول إلزامية غير مملوءة",
      E643: "اسم سند الاستلام غير صالح",
      E644: "المورد لديه بالفعل مستندات",
      E645: 'لا يمكنك تحقيق هذا المستند، كميات بعض المنتجات تساوي 0',
      E648: 'على الأقل فاتورة واحدة مرتبطة بسند إيداع مسودة',
      E664: 'المستند مرتبط بالفعل بسند صرف',
      E663: 'الوسم مرتبط بالفعل بمستند',
      E674: "لديك بالفعل إيداع أساسي.",
      E651: 'على الأقل فاتورة مورد مرتبطة بـ تدفق صرف مخطط',
      E676: 'معرّف الإيداع غير صالح',
      E682: 'لا يمكنك تعديل الموقع الافتراضي',
      E690: 'سند النقل مرتبط بإيداع',
      E691: 'الجرد مرتبط بإيداع',
      E692: 'هذا الإيداع يحتوي على منتجات',
      E695: 'لا يمكن حذف الموقع الذي يحتوي على منتجات',
      E696: 'لا يمكنك حذف الموقع الافتراضي',
      E665: 'اسم المنتج موجود بالفعل',
      E717: 'الصندوق يحتوي على إيداعات/صرفيات',
      E707: 'الصندوق إلزامي',
      E716: 'Collisage غير صالح',
      E715: 'سند إرجاع غير صالح',
      E713: 'سند نقل غير صالح',
      E753: 'المركبة خارج الخدمة',
      E754: 'المركبة مرتبطة بالتدخل',
      E737: 'لوحة الترقيم موجودة سابقا!',
      E712: 'المورد غير صالح',
      E112: 'تجاوز حصة الملفات في حساب الزبون',
      E721: 'لا يمكنك إضافة أكثر من تغليف واحد مفضل',
      E756: 'رقم الهاتفي مستخدمة سابقاً',
      E751: 'امر الإصلاح, مرتبط بتدخل واحد أو أكثر',
      E752: 'أمر إصلاح، مرتبط بعرض أسعار واحد أو أكثر',
      E744: 'إيصال التسليم مستخدم في خدمة ما بعد البيع',
      E759: '\';\'ليس لديك تنسيق صحيح! يجب أن تحتوي كل سطر على عنصرين مفصولين بواسطة',
      E741: 'أرقام الهاتف مستخدمة سابقاً',
      E735: 'المستند يحتوي على أرقام تسلسلية مكررة.',
      E734: 'تحتوي الوثيقة على أرقام تسلسلية موجودة مسبقا',
      E758: 'يحتوي الملف على رمز شريطي واحد أو أكثر غير صحيح',
      E702: 'اختر على الأقل منتجًا واحدًا',
      E761: 'تم الوصول إلى الحد الأقصى',
      E731: 'العملية مرتبطة بتنفيذ',
    },
    popup: {
      newVersion:
        "هناك نسخة جديدة من تطبيق الواجهة الأمامية متاحة، الرجاء النقر أدناه للتحديث.",
      refresh: "تحديث",
      autoPage: "سيتم تحديث الصفحة تلقائيًا في غضون",
      secondes: "ثوانٍ.",
    },
    noFolder: {
      oops: "مرحبًا بك في FAST",
      create1: "يرجى إنشاء ملف لبدء تجربتك مع FAST،",
      create2: "شكرًا لك.",
      add: "إنشاء ملف",
      logout: "تسجيل الخروج",
    },
    noAccess: "غير مصرح لك بأداء هذا الإجراء",
    expiredAccount: "انتهت صلاحية اشتراكك، يرجى التواصل مع المشرف",
    expiredAbonnement: "الاشتراك منتهي",
  },
};
